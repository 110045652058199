import '../styles/components/case-studies-list.scss'

import { Link } from 'gatsby'
import React, { useMemo, useState } from 'react'
import { BsArrowRight } from 'react-icons/bs'

import { useCaseStudies } from '../hooks/cms/use-case-studies'
import { useProducts } from '../hooks/cms/use-products'
import { useSoftware } from '../hooks/cms/use-software'
import { useHrefMaker } from '../hooks/use-href-maker'
import { classNameMap } from '../utils/tsx'
import { CaseStudiesButton } from './button'
import { Image } from './image'

export interface CaseStudiesListProps {
  strings: {
    allProductsLabel: string
    ctaButton: string
    productsLabel: string
  }
  filtersEnabled?: boolean
  productId?: string
  softwareId?: string
  excludedCaseStudyId?: string
  max?: number
}

export const CaseStudiesList: React.FC<CaseStudiesListProps> = ({
  strings: {
    allProductsLabel = 'All',
    ctaButton = 'Learn more',
    productsLabel = 'Products',
  } = {},
  filtersEnabled = true,
  productId = '',
  softwareId = '',
  excludedCaseStudyId = '',
  max,
}) => {
  const caseStudies = useCaseStudies()
  const products = useProducts()
  const software = useSoftware()
  const makeHref = useHrefMaker()

  const [selectedProduct, setSelectedProduct] = useState(-1)

  const [productsIds, softwareIds] = useMemo(() => {
    const productsIds = new Map<string, number>()
    const softwareIds = new Map<string, number>()

    for (const cs of caseStudies.list) {
      for (const prod of cs.products ?? []) {
        if (products.table.has(prod)) {
          productsIds.set(prod, (productsIds.get(prod) ?? 0) + 1)
        }
      }
      for (const soft of cs.software ?? []) {
        if (software.table.has(soft)) {
          softwareIds.set(soft, (softwareIds.get(soft) ?? 0) + 1)
        }
      }
    }

    return [productsIds, softwareIds]
  }, [caseStudies.list, products.table, software.table])

  const filteredCaseStudies = useMemo(() => {
    if (selectedProduct === -1 && !productId && !excludedCaseStudyId) {
      return caseStudies.list
    }

    if (productId) {
      const filteredCaseStudies = caseStudies.list.filter(x =>
        x.products?.includes(productId)
      )
      if (max) {
        return filteredCaseStudies.slice(0, max)
      }
      return filteredCaseStudies
    }
    if (excludedCaseStudyId) {
      return caseStudies.list.filter(
        ({ identifier }) => identifier !== excludedCaseStudyId
      )
    }

    const isProduct = selectedProduct < productsIds.size

    const [id] = isProduct
      ? Array.from(productsIds)[selectedProduct]
      : Array.from(softwareIds)[selectedProduct - productsIds.size]

    return caseStudies.list.filter(x =>
      isProduct ? x.products?.includes(id) : x.software?.includes(id)
    )
  }, [
    selectedProduct,
    productId,
    excludedCaseStudyId,
    productsIds,
    softwareIds,
    caseStudies.list,
    max,
  ])

  const productsFilterItems = useMemo(() => {
    return [
      <div
        role="button"
        key={-1}
        className={classNameMap({
          selected: selectedProduct === -1,
        })}
        onClick={() => setSelectedProduct(-1)}
      >
        {allProductsLabel}
        <span>{caseStudies.list.length}</span>
      </div>,

      ...Array.from(productsIds).map(([prod, count], i) => (
        <div
          role="button"
          key={i}
          className={classNameMap({ selected: selectedProduct === i })}
          onClick={() => setSelectedProduct(i)}
        >
          {products.table.get(prod)!.name}
          <span>{count}</span>
        </div>
      )),
      ...Array.from(softwareIds).map(([soft, count], i) => (
        <div
          role="button"
          key={i + productsIds.size}
          className={classNameMap({
            selected: selectedProduct === i + productsIds.size,
          })}
          onClick={() => setSelectedProduct(i + productsIds.size)}
        >
          {software.table.get(soft)!.name}
          <span>{count}</span>
        </div>
      )),
    ]
  }, [
    allProductsLabel,
    caseStudies.list.length,
    products.table,
    productsIds,
    selectedProduct,
    software.table,
    softwareIds,
  ])

  return (
    <div className="case-studies-list">
      {filtersEnabled && (productId || softwareId) && (
        <div className="case-studies-list__filters">{productsFilterItems}</div>
      )}
      <div className="case-studies-list__items">
        {filteredCaseStudies.map(item => (
          <div className="case-studies-list__item" key={item.identifier}>
            <div className="case-studies-list__item-image">
              <Image path={item.media?.previewImage ?? ''} />
            </div>
            <div className="case-studies-list__item-text">
              <CaseStudiesButton />
              <div>
                <div className="case-studies-list__item-title">
                  {item.summary.title}
                </div>
                <p className="case-studies-list__item-description">
                  {item.summary.previewHeading}
                </p>
                <p className="case-studies-list__item-description-alt">
                  {item.summary.previewDescription}
                </p>
                {!(
                  item.products?.length === 0 && item.software?.length === 0
                ) && (
                  <div className="case-studies-list__item-products">
                    <strong>{productsLabel}:</strong>

                    {(item.products ?? [])
                      .filter(id => products.table.has(id))
                      .map(id => (
                        <Link key={id} to={makeHref(`/products/${id}`)}>
                          {products.table.get(id)!.name}
                        </Link>
                      ))}
                    {(item.software ?? [])
                      .filter(id => software.table.has(id))
                      .map(id => (
                        <Link key={id} to={makeHref(`/software/${id}`)}>
                          {software.table.get(id)!.name}
                        </Link>
                      ))}
                  </div>
                )}
              </div>

              <div className="case-studies-list__item-cta">
                <figure />

                <Link to={makeHref(`/case-studies/${item.identifier}`)}>
                  {ctaButton}

                  <BsArrowRight />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
