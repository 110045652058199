import React from 'react'

export interface YTVideoProps {
  link: string
  width?: string | number
  aspectRatio?: string
}

export const YTVideo: React.FC<YTVideoProps> = ({
  link,
  width = 'auto',
  aspectRatio = '16 / 9',
}) => {
  return (
    <iframe
      style={{ aspectRatio, flexBasis: width, minWidth: width }}
      width={width}
      height={'auto'}
      src={link}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    />
  )
}
